<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M19 9C19 6.23858 21.2386 4 24 4C26.7614 4 29 6.23858 29 9C29 11.7614 26.7614 14 24 14C22.0802 14 20.4132 12.9181 19.5753 11.3307L12.6933 14.7717C12.8917 15.3103 13 15.8925 13 16.5C13 16.7885 12.9756 17.0713 12.9287 17.3464L19.5751 20.6696C20.4129 19.0821 22.0801 18 24 18C26.7614 18 29 20.2386 29 23C29 25.7614 26.7614 28 24 28C21.2386 28 19 25.7614 19 23C19 22.8739 19.0047 22.7488 19.0138 22.6251L12.198 19.2171C11.3069 20.5911 9.75958 21.5 8 21.5C5.23858 21.5 3 19.2614 3 16.5C3 13.7386 5.23858 11.5 8 11.5C9.43137 11.5 10.7223 12.1015 11.6337 13.0654L19.0139 9.37536C19.0047 9.25144 19 9.12627 19 9ZM24 6C22.3431 6 21 7.34315 21 9C21 10.6569 22.3431 12 24 12C25.6569 12 27 10.6569 27 9C27 7.34315 25.6569 6 24 6ZM8 13.5C6.34315 13.5 5 14.8431 5 16.5C5 18.1569 6.34315 19.5 8 19.5C9.65685 19.5 11 18.1569 11 16.5C11 14.8431 9.65685 13.5 8 13.5ZM21 23C21 21.3431 22.3431 20 24 20C25.6569 20 27 21.3431 27 23C27 24.6569 25.6569 26 24 26C22.3431 26 21 24.6569 21 23Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconShare",
});
</script>
